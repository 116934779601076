import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../../components/PageWrapper";
import RichTextEditor from "../../components/RichTextEditor";
import ImageUploader from "../../components/ImageUploader";
import { AuthContext } from "../../context/AuthContext";
import { validation } from "../../utils/validation";
import notify from "../../utils/notify";
import { updateBlog, fetchBlogDetails } from "../../api";

const BlogEdit = ({ params }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [blog, setBlog] = useState(null);
  const param = params[`*`];
  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();
  const { auth } = useContext(AuthContext);

  const getBlogDetails = async (blogId) => {
    const details = await fetchBlogDetails(blogId);
    setBlog(details);
    setLoading(false);
  };

  useEffect(() => {
    if (!auth || !auth?.admin) {
      navigate("/", { replace: true });
    }
    const blogId = param;
    getBlogDetails(blogId);
    return () => {
      setLoading(true);
      setSubmitting(false);
    };
  }, []);

  const onEditorStateChange = (data) => {
    setValue("content", data);
  };

  const onImageUploadChange = (data) => {
    setValue("cover", data[0].file);
  };

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);
      const blogId = blog.id;

        const formData = _.omit(data, ["cover"]);

        const payload = {
          ...formData,
        };
        reset();
        await updateBlog(blogId, payload);
        notify("Blog updated successfully", "success");
        setTimeout(() => {
          navigate("/blog-posts");
        }, 500);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="mb-9">
                  {loading ? (
                    <Skeleton
                      height={50}
                      containerClassName="w-40"
                      className="w-40"
                    />
                  ) : (
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="d-flex align-items-center ml-4"
                    >
                      {" "}
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                      <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                        Back
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-xxl-10 col-lg-10 col-md-10">
                {loading ? (
                  <Skeleton
                    height={100}
                    containerClassName="text-center w-100"
                    className="w-70"
                  />
                ) : (
                  <h3 className="card-title font-size-8 font-weight-bold text-center">
                    Update {blog?.title}
                  </h3>
                )}
              </div>
            </div>

            <div className="row justify-content-center pt-12">
              <div className="col-12 col-xxl-10 col-lg-10 col-md-10">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="title"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Blog Title*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        defaultValue={blog?.title}
                        ref={register(validation.title)}
                      />
                      <span className="text-danger small">
                        {errors?.title?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={400}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <Controller
                        name="content"
                        control={control}
                        defaultValue=""
                        rules={validation.content}
                        as={
                          <RichTextEditor
                            onEditorStateChange={onEditorStateChange}
                            initialValue={blog?.content}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.content?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={200}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group mb-8">
                      <label
                        htmlFor="cover"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Cover Image
                      </label>
                      <div>
                        <Controller
                          name="cover"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          as={
                            <ImageUploader
                              onImageUploadChange={onImageUploadChange}
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.cover?.message || ""}
                        </span>
                      </div>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="published"
                        className="gr-check-input d-flex  mr-3"
                      >
                        <input
                          className="d-none"
                          type="checkbox"
                          id="published"
                          name="published"
                          defaultChecked={blog?.published ? true : false}
                          ref={register(validation.published)}
                        />
                        <span className="checkbox mr-5"></span>
                        <span className="font-size-4 mb-0 line-height-reset mb-1 d-block">
                          Publish Blog
                        </span>
                      </label>
                      <span className="text-danger small">
                        {errors?.published?.message || ""}
                      </span>
                    </div>
                  )}

                  <div className="form-group mb-8">
                    {loading ? (
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-medium w-50 rounded-5 text-uppercase focus-reset"
                        disabled={submitting || loading ? true : false}
                      >
                        {submitting ? "Updating..." : "Update"}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default BlogEdit;
